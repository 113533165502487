<template>
  <div class="linkRank">
    <van-nav-bar
        title
        left-text
        left-arrow
        :fixed="false"
        :border="false"
        :safe-area-inset-top="false"
        @click-left="onClickLeft"
      />
    <div class="map">
      <div class="title" style="margin-top: 0;">任务链路图</div>
      <div id="linkRank"></div>
    </div>
    <div class="title">任务排行榜</div>
    <div class="nav">
      <div class="navItem" :class="[queryData.type == 1 ? 'on' : '']" @click="typeChange(1)">人员排行</div>
      <div class="navItem" :class="[queryData.type == 2 ? 'on' : '']" @click="typeChange(2)">区域排行</div>
      <div class="navItem" :class="[queryData.type == 3 ? 'on' : '']" @click="typeChange(3)">行业排行</div>
    </div>
    <van-tabs
      v-model:active="activeName"
      background="transparent"
      title-inactive-color="rgba(255, 255, 255, 0.8)"
      title-active-color="#459DFF"
      color="#2B8FFF" @click-tab="onClickTab">
      <van-tab title="周" name="1"></van-tab>
      <van-tab title="月" name="2"></van-tab>
      <van-tab title="季度" name="3"></van-tab>
      <van-tab title="年" name="4"></van-tab>
    </van-tabs>
    <div class="rankList">
      <div class="rankItem" :class="[item.rank == 1 ? 'one' : '',item.rank == 2 ? 'two' : '',item.rank == 3 ? 'three' : '']" v-for="(item,idx) in list" :key="idx">
        <div class="rankLeft">
          <span :title="parseInt(item.rank) < 10 ? '0'+parseInt(item.rank) : parseInt(item.rank)">{{item.rank}}</span>{{item.name}}
        </div>
        <div class="rankRight">
          <img src="../../public/statics/images/linkRank/star.png">{{item.score}}
        </div>
      </div>
      <!-- <van-empty class="custom-image" v-show="list && list.length==0" image="http://localhost:8081/speakerh5/statics/images/linkRank/noData.png" description="暂无数据" /> -->
      <van-empty class="custom-image" v-show="list && list.length==0" :image="nodataPic" description="暂无数据" />
    </div>
    <div style="height:3rem;"></div>
    <index-footer></index-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import * as echarts from 'echarts';
import indexFooter from './footer.vue'
import Cookies from "js-cookie";

export default {
  name: 'linkRank',
  setup() {
    const onClickLeft = function () {
      history.back()
    }
    return {
      onClickLeft,
    };
  },
  data () {
    return {
      nodataPic: '',
      list: '',
      myChart: '',
      activeName: '1',
      queryData: {
        id: '',
        type: '1',
        date_type: '1',
      },
      getCodeUrl: "http://speaker.shanmuzhi.com/api/wxcode",
      memberUrl: "http://speaker.shanmuzhi.com/#/",
      star: require("../../public/statics/images/star.png")
	  }
  },
  components: {
    indexFooter
  },
  created () {
    this.getCodeUrl = this.$publicData.domain + "api/wxcode";
    this.memberUrl = this.$publicData.domain + "#" + this.$route.fullPath;
    this.nodataPic = this.$publicData.domain + '/speakerh5/statics/images/linkRank/noData.png'
    // window.document.title = '“新文化符号”出海'
	},
  mounted () {
    let that = this
    var token = Cookies.get("spToken");
    if (token && token != undefined) {
      // 有token 直接展示
      that.getDutyRank()
      setTimeout(function(){
        that.shareFun()
      },700)
      return;
    }
    if(this.$route.query.url !=undefined){
      Cookies.set('articleId', this.$route.query.id)
      Cookies.set('title', this.$route.query.title)
      Cookies.set('desc', this.$route.query.desc)
      Cookies.set('imgUrl', this.$route.query.imgUrl)
    }
    // 判断是否已注册，如果注册则正常微信登录，如果没有则先注册。
    var codeArr = this.getUrlCode();
    // 获取code
    if (codeArr["code"] != undefined) {
      var code = codeArr["code"];
      var params = {
        code: code
      };
      // 绑定
      this.$api.index.wxlogin(params).then(res => {
        if (res.code == 200) {
          localStorage.setItem("priv", JSON.stringify(res.result.priv));
          Cookies.set("spToken", res.result.token, { expires: 365 });
          Cookies.set("id", res.result.id, { expires: 365 });
          that.getDutyRank()
          setTimeout(function(){
            that.shareFun()
          },700)
          window.history.replaceState(null, null, that.memberUrl);
        } else {
          location.href=that.$publicData.domain
        }
      });
    } else {
      window.location.href = this.getCodeUrl;
    }
  },
  beforeUnmount() {
    this.myChart && this.myChart.dispose();
  },
  methods: {
    // 截取url中的code方法
    getUrlCode() {
      var allUrl = location.href;
      var url = allUrl.substring(allUrl.indexOf("?"), allUrl.length);
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    onClickTab(tab) {
      console.log(tab)
      this.queryData.date_type = tab.name
      this.getDutyRank()
    },
    typeChange(val) {
      this.queryData.type = val
      this.activeName = 1
      this.queryData.date_type = 1
      this.getDutyRank()
    },
    getDutyRank() {
      let that = this;
      that.queryData.token = Cookies.get("spToken")
      that.queryData.id = this.$route.query.id || Cookies.get('articleId')
      that.$api.index.dutyRank(that.queryData).then(function(response) {
        if (response.code === 200) {
          // console.log(response.result)
          that.list = response.result
          !that.myChart && that.ChartInit()
        }
      });
    },
    ChartInit(){
      let chartDom = document.getElementById('linkRank')
      this.myChart = echarts.init(chartDom)
      this.myChart.showLoading()
      this.chartUpdata()
    },
    chartUpdata (){
      let that = this
      this.myChart.showLoading()
      let ps = {
        token: Cookies.get("spToken"),
        duty_id: this.$route.query.id
      }
      this.$api.index.dutyMap(ps).then(function (res) {
          if (res.code == 200) {
          // console.log(res)
          let graph = res.result
          that.myChart.hideLoading();
          graph.nodes.forEach(function(node) {
            let name = node.name.replace(/\s*/g, '')
            node.name = name.length>10 ? (name.slice(0,10) + '...') : name
            node.symbolSize = (node.symbolSize * 2) / 3;
            if(node.id == 0){
              node.symbolSize = node.symbolSize + 10
              node.symbol='image://'+that.star
            }
          });
          let option = {
            title: {},
            color: [
              {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 0.5,
                colorStops: [
                  {
                    offset: 0,
                    color: "#376FFF" // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#62A5FF" // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
            ],
            tooltip: {
              trigger: 'item',
              axisPointer: {
                type: "shadow",
              },
              textStyle: {
                fontSize: 13,
                color: "#fff",
              },
              // position: ['50%', '50%'], // 弹层定位
              // 自定义弹窗样式
              padding: 0,
              backgroundColor: "transparent",
              formatter: function(params){
                let name = params.name.replace(/\s*/g, '')
                let arr = params.name.split('>')
                console.log(params)
                // console.log(arr)
                // console.log(params.data.source)
                // console.log(params.data.target)
                if (name.indexOf('>') != -1 && Number(arr[0]) == params.data.source && Number(arr[1]) == params.data.target) {
                  return ''
                }
                let str = `<div style = " padding: 5px 10px;
                                        background: -webkit-radial-gradient(circle, rgba(0,86,165,0.70),rgba(0,86,165,0.5)) !important;
                                        background: -o-radial-gradient(circle, rgba(0,86,165,0.70),rgba(0,86,165,0.5)) !important;
                                        background: -moz-radial-gradient(circle, rgba(0,86,165,0.70),rgba(0,86,165,0.5)) !important;
                                        background: radial-gradient(circle,rgba(0,86,165,0.70),rgba(0,86,165,0.5)) !important;
                                        no-repeat center center ;
                                        width:100%;height:100%; ">阅读贡献量 ：${params.value}</div>`; // 设置显示内容
                return str;
              }
            },
            legend: [
              {
                // selectedMode: 'single',
                data: graph.categories.map(function(a) {
                  // return a.name;
                })
              }
            ],
            series: [
              {
                name: "",
                type: "graph",
                layout: "force",
                data: graph.nodes,
                links: graph.links,
                categories: graph.categories,
                roam: true, // 拖动
                edgeLabel: {
                  show: false
                },
                label: {
                  position: "bottom",
                  color: "#D4ECFF", // 每个节点下的文字显示
                  // color: "rgba(0,0,0,0)", // 每个节点下的文字显示
                  formatter: "{b}",
                  show: true // 每个点的文字显示
                },
                force: {
                  repulsion: $(window).width() * 50 // 控制图表大小
                  // edgeLength: 500,
                  // layoutAnimation: true
                },
                lineStyle: {
                  color: "#5383f1",
                  width: 1,
                  opacity: 0.5
                }
              }
            ]
          };
          that.myChart.setOption(option);
          that.myChart.on("click", function(params) {
            console.log(params.name);
          });
        }
      })
    },
    shareFun() {
      var that = this;
      var tempurl = location.href;
      // const url = location.href.split("#")[0];
      const url = location.href;
      console.log("Iframe ---share----url=" + url);
      // const shareUrl = location.href.split("#")[0];
      that.$api.index.getShare({ url: tempurl }).then(res => {
        if (res.code == 200) {
          that.shareConfig = res.result;
          wx.config({
            debug: that.shareConfig.debug,
            beta: that.shareConfig.beta,
            jsApiList: that.shareConfig.jsApiList,
            appId: that.shareConfig.appId,
            nonceStr: that.shareConfig.nonceStr, // 随机串
            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
            signature: that.shareConfig.signature // 签名
          });
          // console.log('Iframe ---微信分享设置---开始');
          // 分享
          wx.ready(function() {
            var shareDataA = {
              title: that.$route.query.title || Cookies.get('title'), // 分享标题
              // desc: '' || that.$publicData.shareDesc, // 分享描述
              desc: that.$route.query.desc || Cookies.get('desc'), // 分享描述
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$route.query.imgUrl || Cookies.get('imgUrl'), // 分享图标
              success: function() {},
              cancel: function(e) {}
            };

            var shareDataB = {
              title: that.$route.query.title || Cookies.get('title'), // 分享标题
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$route.query.imgUrl || Cookies.get('imgUrl'), // 分享图标
              success: function() {},
              cancel: function(e) {}
            };
            wx.updateAppMessageShareData(shareDataA); // 分享给朋友
            wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
            // console.log('Iframe ---微信分享设置---结束');
          });
        }
      });
    },
    toPath (url, obj) {
      if (this.$route.fullPath == url) {
        return
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        })
        window.location.reload()
        return
      }
      this.$router.push({
        path: url,
        query: obj
      })
    }
  }
}
</script>
<style scoped>
.linkRank .map{
  padding-top: 0.1rem;
}
</style>
<style>
.linkRank .custom-image{
  margin: 64px auto;
  width: 4.8rem;
  height: 3.54rem;
}
.linkRank .custom-image .van-empty__image{
  margin-left: 34px;
}
.linkRank .custom-image .van-empty__description{
  color: #c9dcff;
  padding: 0;
}
.linkRank .van-nav-bar .van-icon {
  color: #fff;
}
.linkRank .van-nav-bar--fixed{
  background: transparent;
}
.linkRank .van-nav-bar{
  background: transparent;
}
</style>
